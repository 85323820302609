import React from "react"
import { graphql } from "gatsby"
import { styled } from "twin.macro"
import * as dot from "dot-wild"
//
import Layout from "../components/layout/Layout"
import { getPreviewCurso } from "../utils/preview"
import { Container } from "../storybook/components/misc/Layouts"
import { createImageFluid } from "../utils/image"

const PreviewLoader = styled.div`
  text-align: center;
  margin-top: 20%;
  font-weight: bold;
  font-size: 150%;
`

const PreviewContainer = styled.div`
  position: fixed;
  left: 10px;
  top: 10px;
  padding: 4px 8px;
  background-color: #000;
  color: #fff;
  z-index: 9999;
  text-align: center;
  border: 1px solid #fff;
`

const transformEntry = (entry) => {
  const flatten = dot.flatten(entry)

  let newEntry = {}

  Object.keys(flatten).forEach((key) => {
    let newKey = key
    if (/.*Collection\.items\..*/.test(newKey)) {
      newKey = newKey.replace(/Collection\.items/, "")
    }
    if (/sys\.id$/.test(newKey)) {
      newKey = newKey.replace("sys.id", "id")
    }
    if (/.*\.url$/.test(newKey) && /.*images\.ctfassets\.net.*/.test(flatten[key])) {
      newKey = newKey.replace(/\.url$/, "")
      newEntry[newKey] = createImageFluid({ url: flatten[key], width: 960, height: 640 })
    } else if (/.*\.json\..*/.test(newKey)) {
      newKey = newKey.replace(/\.json\..*/, ".raw")
      if (!newEntry[newKey]) {
        newEntry[newKey] = JSON.stringify(dot.get(entry, key.replace(/\.json\..*/, ".json")))
      }
    } else {
      newEntry[newKey] = flatten[key]
    }
  })

  const expandedEntry = dot.expand(newEntry)

  return expandedEntry
}

const Preview = ({ location, data }) => {
  const urlParams = new URLSearchParams(location?.search)
  const slug = urlParams?.get("slug") || "homepage"

  const variables = { slug, locale: "pt" }

  const [body, setBody] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)
    getPreviewCurso(variables).then((page) => {
      const transformed = transformEntry(page)
      setBody(
        <div>
          <div>todo: preview</div>
          <pre>{JSON.stringify(transformed, null, 2)}</pre>
        </div>
      )
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug])

  if (loading) {
    return <PreviewLoader>Loading preview...</PreviewLoader>
  }

  return (
    <Layout key={`preview-${slug}`} tags={{ title: "Preview for..." }}>
      <PreviewContainer>Preview Mode</PreviewContainer>
      <Container id="main-section">{body}</Container>
    </Layout>
  )
}

export default Preview

export const previewQuery = graphql`
  query ($site: String!, $locale: String!) {
    contentfulSite(nome: { eq: $site }, node_locale: { eq: $locale }) {
      ...FragmentContentfulSite
    }

    site {
      ...FragmentSite
    }
  }
`
